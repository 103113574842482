import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from 'gatsby-background-image'

const BackgroundContests = () => {
  const { desktop } = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "hero-images/concorsi.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const imageData = desktop.childImageSharp.fluid

  return (
    <BackgroundImage
      Tag="div"
      className=""
      fluid={imageData}
      backgroundColor={`#252525`}
      role="img"
      preserveStackingContext={true} />
  )
};

export default BackgroundContests;
