import React from 'react';
import PropTypes from "prop-types";

import { format } from 'date-fns';


const ContestMicroData = ({contest}) => {

  const {
    contestTitle,
    contestText,
    contestStartDate,
    contestEndDate,
    contestImage
  } = contest;

  const formattedStartDate = contestStartDate ? format(new Date(contestStartDate), "yyyy-LL-dd") : null;
  const formattedEndDate = contestEndDate ? format(new Date(contestEndDate), "yyyy-LL-dd") : null;

  const structuredData =
    {
      "@context": "https://schema.org",
      "@type": "Event",
      "name": contestTitle,
      "startDate": formattedStartDate,
      "endDate": formattedEndDate || null,
      "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
      "eventStatus": "https://schema.org/EventScheduled",
      "location": {
        "@type": "VirtualLocation",
        "name": "www.ceres.com",
        "url": "https://www.ceres.com/concorsi"
      },
      "image": [
        `${process.env.GATSBY_CMS_ASSETS_BASEURL}${contestImage.fullpath}`
      ],
      "description": contestText
    };

  return (
    <script type="application/ld+json">
      {JSON.stringify(structuredData)}
    </script>
  )
};

ContestMicroData.propTypes = {
  contest: PropTypes.object.isRequired
};

export default ContestMicroData;
