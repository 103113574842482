import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import { format } from 'date-fns';
import "./contests.scss";
import ContestMicroData from "../contestMicrodata";

import CONST from "../../config/constants";
import ButtonLink from "../buttonLink";
import {compareDisplayOrderDesc} from "../../helpers";
import 'fix-date';

const ContestDate = ({date}) => {

  const monthName = (month) => {
    const m = parseInt(month);
    if(m >= 1 && m <= 12)
      return CONST.MONTHS[parseInt(month)-1] && CONST.MONTHS[parseInt(month)-1].substring(0, 3);
    return false;
  };

  date = date.replace("Z","");
  const d = new Date(date);

  return (
    <span className="ContestDate">
      <span className="day">{format(d,'d')}</span>
      <span className="month">{monthName(format(d,'L'))}</span>
      <span className="year">{format(d,'yyyy')}</span>
    </span>
  );

};


const Contests = () => {

  const data = useStaticQuery(graphql`
    query {
      cmsApi {
        getContestListing {
          edges {
            node {
              id
              contestTitle
              contestStartDate
              contestEndDate
              contestSubTitle
              contestText
              contestCta {
                text
                path

              }
              contestImage {
                fullpath
              }
            }
          }
        }
      }
    }
  `);
  const contests = data.cmsApi.getContestListing.edges;
  // console.log(contests);
  contests.sort(compareDisplayOrderDesc);
  return (

    <div className="Contests">

      { contests && contests.length && contests.map(({node}, i) => {

        const {
          contestTitle,
          contestStartDate,
          contestEndDate,
          contestSubTitle,
          contestText,
          contestCta,
          contestImage
        } = node;

        return (
          <div
            className="contest"
            key={i}
          >

            <h2 dangerouslySetInnerHTML={{__html: contestTitle}}/>

            <div className="row">
              <div className="col">
                <div className="mobile-only">
                  {contestStartDate &&
                    <div className="date start-date">
                      { contestEndDate &&
                        <span>Dal</span>
                      }
                      <ContestDate date={contestStartDate}/>
                    </div>
                    }
                    {contestEndDate &&
                    <div className="date end-date">
                      <span>al</span>
                      <ContestDate date={contestEndDate}/>
                    </div>
                  }
                </div>
                <div className="img">
                  <img src={`${process.env.GATSBY_CMS_ASSETS_BASEURL}${contestImage.fullpath}`} alt={contestTitle}/>
                </div>
              </div>
              <div className="col">
                <div className="txt">

                  {contestStartDate &&
                    <div className="date start-date">
                      { contestEndDate &&
                        <span>Dal</span>
                      }
                      <ContestDate date={contestStartDate}/>
                    </div>
                  }
                  {contestEndDate &&
                    <div className="date end-date">
                      <span>al</span>
                      <ContestDate date={contestEndDate}/>
                    </div>
                  }

                  <h3 dangerouslySetInnerHTML={{__html: contestSubTitle}}/>

                  {contestText &&
                    <p dangerouslySetInnerHTML={{__html: contestText}}/>
                  }
                  {contestCta && contestCta.path &&
                    <ButtonLink
                      to={contestCta.path}
                      target={contestCta.path.includes(`http`) ? `_blank` : `_self`}
                      label={contestCta.text}
                    />
                  }
                </div>
              </div>
            </div>

            <ContestMicroData contest={node}/>

          </div>
        );

      })}

    </div>
  );

};

export default Contests;
