import React from 'react';
import FullpageSection from "../fullpageSection"
import BackgroundContests from "../backgroundImages/backgroundContests"

import {resolveLinkToRoute} from "../../helpers";

import contents from "./fullpageSectionContests.yml";

const FullpageSectionContests = () => {

  const {
    titleTag,
    title,
    subtitle,
    text,
    link
  } = contents;

  return (
    <FullpageSection
      whichTitle={titleTag}
      copyTitle={title}
      haveSubtitle={!!(subtitle && subtitle !== '')}
      copySubtitle={subtitle}
      copyText={text}
      haveCTA={!!(link && link.to && link.to !== '')}
      buttonLinkTo={link && resolveLinkToRoute(link.to)}
      buttonLinkTarget={link && link.target}
      buttonLinkLabel={link && link.label}
      buttonLinkTrackingCategory={link && link.trackingCategory}
    >
      <BackgroundContests />
    </FullpageSection>
  )
};

export default FullpageSectionContests;
