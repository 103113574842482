import React from "react"
import SEO from "../components/seo"
import FullpageSectionContests from "../components/fullpageSectionContests";
import Contests from "../components/contests/contests";

const ContestsPage = () => {

  return (
    <main>
      <SEO
        title="Concorsi"
        description=""
      />

      <FullpageSectionContests/>

      <Contests/>

    </main>
  );

};

export default ContestsPage;
